<template>
  <div class="page what-we-do" v-if="page" :loaded="Boolean(page)">
    <section class="top-section">
      <div class="wrapper">
        <div class="container">
          <div class="content">
            <div class="title">About us</div>
            <h3>{{page.title}}</h3>
            <h5>{{page.tagline}}</h5>
            <p v-html="page.description.replace(/\n/g, '<br>')"></p>
            <div class="buttons">
              <router-link to="/contact" class="btn">Contact us</router-link>
            </div>
          </div>
          <div class="section-image">
            <img :src="page.image" alt="">
          </div>
        </div>
      </div>
    </section>

    <section :class="`category ${i % 2 ? 'odd' : 'even'}`" v-for="(section, i) in page.sections" :key="section.id">
      <div class="wrapper">
        <div class="container">
          <div class="category-image">
            <img :src="section.image" :alt="section.name">
          </div>
          <div class="content">
            <h3 :style="{ color: section.color }">{{section.name}}</h3>
            <h5 v-html="section.tagline.replace(/\n/g, '<br>')"></h5>
            <div class="category-description" v-html="section.description"></div>
            <div class="category-headings" :style="{ color: section.color }">
              <div v-for="heading in section.headings" :key="heading.title">
                <a class="category-heading" v-if="heading.is_link" :href="heading.link" target="_blank" rel="noopener noreferrer">
                  {{heading.title}}
                  <Icon type="fas" icon="link" size="small" />
                </a>
                <span v-else class="category-heading">
                  {{heading.title}}
                </span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  </div>
</template>

<script>
import Icon from "./Icon.vue";
import { mapGetters } from "vuex";

export default {
  name: "WhatWeDo",
  components: {
    Icon,
  },
  data() {
    return {
      page: null,
      loaded: false,
      backgroundImage: null,
      selectedSection: 0,
      scrolled: false,
      pages: [],
    };
  },
  computed: {
    ...mapGetters({
      settings: "getSettings",
      isMobile: "getMobile",
      testMode: "getTest",
    }),
    db() {
      return this.$store.state.firebase.firestore;
    },
  },
  methods: {
    getPage() {
      if (this.pageRef) this.pageRef();
      this.pageRef = this.db()
        .collection(`${this.testMode ? "test_" : ""}pages`)
        .doc("whatwedo")
        .onSnapshot((page) => {
          this.page = {
            title: "",
            description: "",
            image: "",
            ...page.data(),
          };
        });
    },
  },
  watch: {
    testMode() {
      this.getPage();
    },
  },
  mounted: function() {
    this.getPage();
  },
  beforeDestroy: function() {
    if (this.pageRef) this.pageRef();
  },
};
</script>

<style scoped>
.top-section {
  position: relative;
  margin-bottom: 258px;
}

.top-section .container {
  position: relative;
  display: flex;
  align-items: center;
  background: rgba(28, 172, 214, 0.08) url(../assets/images/wb_o_wwd.svg) no-repeat -35% 25%/auto 150%;
  border-radius: 16px;
  min-height: 760px;
  padding: 48px 0;
  margin-right: 144px;
}

.top-section .container .content {
  position: relative;
  width: calc(50% + 72px);
  padding: 0 42px 0 96px;
  min-width: 420px;
  z-index: 2;
}

.top-section .container .content h3 {
  font-size: 72px;
}

.top-section .container .content h5 {
  margin: 16px 0 32px;
  line-height: 1.2;
  color: #656662;
}

.top-section .container .content p {
  font-size: 18px;
}

.top-section .content .buttons {
  margin-top: 64px;
}

.top-section .section-image {
  position: absolute;
  top: 24px;
  right: -144px;
  width: calc(50% + 72px);
  bottom: 24px;
  border-radius: 16px;
  background-color: #fafafa;
  overflow: hidden;
}

.top-section:before {
  content: '';
  position: absolute;
  width: 7px;
  height: 192px;
  left: 25%;
  top: 100%;
  margin-top: 32px;
  background: url(../assets/images/wb_o_spacer.svg) no-repeat center/contain;
}

.top-section .content .title {
  display: flex;
  align-items: center;
  color: #0A7E8C;
  font-size: 20px;
  margin-bottom: 24px;
}

/* .top-section .content .title:before {
  content: '';
  display: block;
  width: 112px;
  height: 21px;
  background: url(../assets/images/wb_o_4dots.svg) no-repeat center/contain;
  margin-right: 24px;
} */

.top-section .section-image img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 16px;
}

.category {
  margin-bottom: 328px;
}

.category:last-child {
  margin-bottom: 96px;
}

.category .container {
  display: flex;
}

.category.odd .container {
  flex-direction: row-reverse;
}

.category .container .category-image {
  flex-grow: 1;
  height: 580px;
  margin-right: 96px;
}

.category.odd .container .category-image {
  margin-right: 0;
  margin-left: 96px;
}

.category .content {
  position: relative;
  width: 812px;
  max-width: 55%;
  flex-shrink: 0;
  margin-top: 24px;
}

.category:not(:last-child) .content:after {
  content: '';
  position: absolute;
  top: 100%;
  left: 0;
  right: 0;
  width: 7px;
  height: 192px;
  margin: 64px auto 0;
  background: url(../assets/images/wb_o_spacer.svg) no-repeat center/contain;
}

.category .content h5 {
  color: #656662;
  margin: 24px 0 32px;
}

.category .container .category-image img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 16px;
}

.category .content .category-headings {
  display: flex;
  flex-wrap: wrap;
  margin-top: 40px;
}

.category .content .category-heading {
  display: flex;
  align-items: center;
  padding: 0 24px;
  height: 42px;
  border-radius: 21px;
  border: 1px solid;
  margin: 0 12px 12px 0;
}

.category .content a.category-heading {
  padding-right: 16px;
}

@media screen and (max-width: 1520px), screen and (max-height: 820px) {
  .category .container .category-image {
    margin-right: 48px;
  }
}

@media screen and (max-width: 1280px) {
  .top-section .container .content h3 {
    font-size: 60px;
  }
}

@media screen and (max-width: 1024px) {
  .category {
    margin-bottom: 280px;
  }
  .category .container,
  .category.odd .container {
    flex-direction: column;
  }
  .category .container .category-image,
  .category.odd .container .category-image {
    height: 300px;
    margin: 0;
  }
  .category .content {
    width: auto;
    max-width: none;
    padding: 0 48px;
  }
  .category:not(:last-child) .content:after {
    margin-top: 48px;
    height: 172px;
  }
  .top-section:before {
    left: 0;
    right: 0;
    margin: 32px auto 0;
  }
  .top-section .container .content h3 {
    font-size: 52px;
  }
  .top-section .section-image {
    position: relative;
    height: 360px;
    right: 0;
    width: calc(100% - 96px);
    top: 0;
    margin: 0 48px;
  }
  .top-section .container {
    flex-direction: column-reverse;
    min-height: auto;
    margin-right: 0;
  }
  .top-section .container .content {
    width: 100%;
    min-width: 0;
    padding: 48px 48px 0;
  }
}

@media screen and (max-width: 800px) {
  .top-section .container .content {
    margin: 0;
    padding: 48px 48px 0;
    max-width: none;
  }
}

@media screen and (max-width: 620px) {
  .category .content {
    padding: 0;
  }
  .category .container .category-image,
  .category.odd .container .category-image {
    height: 200px;
  }
  .top-section .container {
    padding: 24px 0 48px;
  }
  .top-section .section-image {
    width: calc(100% - 48px);
  }
  .top-section .container .content {
    padding: 24px 24px 0;
  }
}

@media screen and (max-width: 480px) {
  .category h3 {
    font-size: 32px;
  }
  .category .content .category-heading {
    font-size: 12px;
  }
  .top-section .container .content {
    padding: 32px;
  }
}
</style>
